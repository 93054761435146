@import "~bootstrap-icons/font/bootstrap-icons.css";

html,
body {
    height: 100%;
    font-family: "Gill Sans" !important;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.valid-icon {
    color: green;
}

.fill-width {
    width: 100%;
}

.mat-drawer-container {
    background-color: inherit !important;
}

.mat-drawer-transition .mat-drawer-backdrop {
    position: fixed;
}

.time-range-input .mat-date-range-input-wrapper {
    flex-grow: 1;
}

.list-filters .mat-mdc-form-field-infix {
    width: 100%;
}



.group-wrapper {
    padding: 24px 16px 24px 16px;
    border: 1px solid #919191;
    position: relative;
    margin-bottom: 32px;
}

.group-title {
    background-color: white;
    position: absolute;
    margin-top: -36px;
    line-height: 16px;
    padding: 4px;
}

mat-card.edit-modal {
    max-height: calc(100% - 24px);
    width: 860px;
    margin: 12px;
}

mat-card.edit-modal mat-card-content {
    overflow-y: auto;
    padding: 12px 16px;
}

mat-card.edit-modal .group-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    column-gap: 1em;
}

mat-card.edit-modal .group-field {
    align-self: center;
    flex-grow: 1;
}

.list-right-menu {
    padding: 12px;
    overflow: visible;
    width: 400px;
}

.list-right-menu-close {
    color: inherit
}

.list-toolbar {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.87) !important;
    white-space: inherit !important;
    flex-wrap: wrap;
    height: auto !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.flex-grow {
    flex-grow: 1;
}

.list-toolbar-spacer {
    flex: 1 1 auto;
}

.d-inline-mobile {
    display: none;
}

.d-none {
    display: none !important;
}

mat-dialog-container {
    width: 884px !important;
}

@media screen and (max-width: 960px) {
    mat-card.edit-modal {
        width: 430px;
    }

    .d-inline-mobile {
        display: inline;
    }

    .d-none-mobile {
        display: none !important;
    }
}

@media screen and (max-width: 652px) {
    mat-dialog-container {
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
        max-width: 100% !important;
        max-height: 100% !important;
    }

    .mat-mdc-dialog-content {
        max-height: unset !important;
    }
}

@media screen and (max-width: 480px) {
    mat-card.edit-modal {
        width: 100%;
        max-height: 100%;
        margin: 0px;
    }

    .list-right-menu {
        width: 100%;
    }
}

.readonly-input .mdc-text-field {
    background-color: whitesmoke;
}

.mat-column-mon,
.mat-column-tue,
.mat-column-wed,
.mat-column-thu,
.mat-column-fri,
.mat-column-missing_days,
.mat-column-jan,
.mat-column-feb,
.mat-column-mar,
.mat-column-apr,
.mat-column-may,
.mat-column-jun,
.mat-column-jul,
.mat-column-aug,
.mat-column-sep,
.mat-column-oct,
.mat-column-nov,
.mat-column-dec,
.mat-column-total,
.mat-column-week_duration,
.mat-column-prev_week_duration {
    width: 1px !important;
}

.card_hoverable {
    border-radius: 15px;
    transition: 0.5s;
}

.card_hoverable:hover {
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.map-container {
    max-width: 100%;
}


.bs-icon {
    --bs-icon-size: .75rem;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    font-size: var(--bs-icon-size);
    width: calc(var(--bs-icon-size) * 2);
    height: calc(var(--bs-icon-size) * 2);
    color: var(--bs-primary);
}

.bs-icon-xs {
    --bs-icon-size: 1rem;
    width: calc(var(--bs-icon-size) * 1.5);
    height: calc(var(--bs-icon-size) * 1.5);
}

.bs-icon-sm {
    --bs-icon-size: 1rem;
}

.bs-icon-md {
    --bs-icon-size: 1.5rem;
}

.bs-icon-lg {
    --bs-icon-size: 2rem;
}

.bs-icon-xl {
    --bs-icon-size: 2.5rem;
}

.bs-icon.bs-icon-primary {
    color: var(--bs-white);
    background: var(--bs-primary);
}

.bs-icon.bs-icon-primary-light {
    color: var(--bs-primary);
    background: rgba(var(--bs-primary-rgb), .2);
}

.bs-icon.bs-icon-semi-white {
    color: var(--bs-primary);
    background: rgba(255, 255, 255, .5);
}

.bs-icon.bs-icon-rounded {
    border-radius: .5rem;
}

.bs-icon.bs-icon-circle {
    border-radius: 50%;
}

.navbar-collapse .search_div {
    display: inline-flex;
}
    
.navbar-collapse li {
    align-self: center;
}

.navbar-collapse a {
    color: inherit !important;
    text-decoration: none !important;
}







@media screen and (max-width: 760px) {
    .navbar-collapse {
        position: relative;
        background-color: #ef19237d;
        padding: 10px;
        margin-right: calc(var(--bs-gutter-x)* -0.5);
        margin-left: calc(var(--bs-gutter-x)* -0.5);
        text-align: center;
        text-align: -webkit-center;
        border-bottom: 1px solid #ccc;
        margin-bottom: 24px;
    }

    .navbar-collapse .search_div {
        position: absolute;
        bottom: 24px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }

    .navbar-collapse ul {
        padding-bottom: 72px;
        font-size: 20px;
    }

    .navbar-collapse li {
        align-self: unset;
    }
    
    .navbar-collapse ul li:not(:last-child) {
        border-bottom: 0px solid #ccc;
    }
    
    .navbar-collapse .nav-item .dropdown {
        padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
        color: inherit !important;
        text-decoration: inherit !important;
    }

    .dropdown-toggle {
        margin-left: 0px !important;
    }
}


    .mobile_body .navbar-collapse {
        position: relative;
        background-color: #ef19237d;
        padding: 10px;
        margin-right: calc(var(--bs-gutter-x)* -0.5);
        margin-left: calc(var(--bs-gutter-x)* -0.5);
        text-align: center;
        text-align: -webkit-center;
        border-bottom: 1px solid #ccc;
        margin-bottom: 24px;
    }

    .mobile_body .navbar-collapse .search_div {
        position: absolute;
        bottom: 24px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }

    .mobile_body .navbar-collapse ul {
        padding-bottom: 72px;
        font-size: 20px;
    }

    .mobile_body .navbar-collapse li {
        align-self: unset;
    }
    
    .mobile_body .navbar-collapse ul li:not(:last-child) {
        border-bottom: 0px solid #ccc;
    }
    
    .mobile_body .navbar-collapse .nav-item .dropdown {
        padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
        color: inherit !important;
        text-decoration: inherit !important;
    }

    .mobile_body .dropdown-toggle {
        margin-left: 0px !important;
    }






.detail_btn {
    background: rgb(253, 13, 13) !important;
    margin-bottom: auto;
    margin-left: auto;
}

.mobile_home_button {
    color: white;
    background: #00000080;
    border: 1px solid white;
    padding: 15px 35px;
    border-radius: 15px;
    font-size: 22px;
    font-style: italic;
    min-width: 100%;
    margin-top: 30px;
}

.mobile_home_button:hover,
.mobile_home_button:active {
    background: #36363680 !important;
    border-color: white !important;
}

.chi-siamo-div {
    color: white;
    background: #00000080;
    border: 1px solid white;
    padding: 15px 35px;
    padding-top: 48px;
    margin: 0 -30px;
    border-radius: 15px;
    min-width: 100%;
}

.chi-siamo-div h2 {
    font-size: 20px;
    margin-bottom: 32px;
}

.chi-siamo-div p {
    font-size: 16px;
}